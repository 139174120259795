/* App.css */
.title, .description {
  margin: 0; /* Removes default margin */
  padding: 0; /* Ensures no padding is adding extra space */
  font-family: 'Montserrat', sans-serif; /* Specifies the font */
  text-shadow: 
    8px 8px 8px rgba(0, 0, 0, 1); /* Subtle yet effective shadow */
}

.title {
  font-size: 72px; /* Large font size for titles */
  margin-bottom: 0px; /* Negative margin to pull elements closer */
  line-height: 1;
  color: #ffffff;
}

.description {
  font-size: 18px; /* Smaller font size for descriptions */
  font-weight: bold;
  margin-top: 0px; /* Negative margin to reduce space */
  text-shadow:
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000;
  color: #ffffff;
}
